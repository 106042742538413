import React from "react";

const Money = ({
  backgroundImage,
  color,
  borderColor,
  borderWidth,
  isRound,
  icon,
  wordText,
  currencyName,
  currency,
  numberText
}) => {
  return (
    <div
      className="relative border-2 border-black bg-white p-7 text-center m-2 money-wrapper"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundColor: color,
        borderColor: borderColor,
        borderWidth: `${borderWidth}px`,
        width: "450px",
        height: "243px",
      }}
    >
      <div
        id="inner"
        className="py-6 px-10"
        style={{
          borderColor: borderColor,
          borderWidth: `${borderWidth}px`,
          width: "390px",
          height: "184px",
        }}
      >
        <div className="flex justify-center items-center">
          <p className="font-bold text-xl">{wordText}</p>
          <img
            className={`${isRound === "1" ? 'rounded-full' : 'rounded-none'} mx-10 p-2`}
            src={icon}
            style={{
              borderColor: borderColor,
              borderWidth: `${borderWidth}px`,
              maxHeight: `100px`,
            }}
          />
          <p className="font-bold text-xl">{wordText}</p>
        </div>
        <div className="text-center mt-4">
          <p className="text-xl font-bold">{currencyName}</p>
        </div>
        <div className="absolute border-2 bg-white border-black top-2 flex h-10 items-center justify-center left-2 rounded-full w-10">
          <span className="font-bold text-l">
            {currency}
            {numberText}
          </span>
        </div>
        <div className="absolute border-2 bg-white border-black bottom-2 flex h-10 items-center justify-center right-2 rounded-full w-10">
          <span className="font-bold text-l">
            {currency}
            {numberText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Money;