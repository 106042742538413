import React, { useState, useEffect } from "react";

import Money from "./Money";

const DesignBuilder = ({ data }) => {
  const [color, setColor] = useState(data.color || "#ffffff");
  const [denomination, setDenomination] = useState(data.denomination || 1);

  let initalCurrency = data.currency || "$";
  if (data.currency === "") {
    initalCurrency = "";
  }
  const [currency, setCurrency] = useState(initalCurrency);

  const [borderColor, setBorderColor] = useState(data.borderColor || "#000000");
  const [borderWidth, setBorderWidth] = useState(data.borderWidth || 2);

  const [currencyName, setCurrencyName] = useState(
    data.currencyName || "Fancy Currency"
  );
  const [numberText, setNumberText] = useState(data.numberText || "1");
  const [wordText, setWordText] = useState(data.wordText || "one");
  const [isRound, setIsRound] = useState(data.isRound || "1");

  const images = window._images || []; // Fallback to an empty array if undefined
  const bgImages = window._bg_images || []; // Fallback to an empty array if undefined

  const iconPath = data.icon || images[0].path;
  const [icon, setIcon] = useState(iconPath);

  const bgImagePath = data.backgroundImage || bgImages[0].path;
  const [backgroundImage, setBackgroundImage] = useState(bgImagePath);

  // Find the index of the current icon in the images array
  const findImageIndex = (iconPath) => images.findIndex(image => image.path === iconPath);
  const [selectedImageIndex, setSelectedImageIndex] = useState(findImageIndex(icon));

  // Find the index of the current icon in the images array
  const findImageBgIndex = (bgImagePath) => bgImages.findIndex(image => image.path === bgImagePath);
  const [selectedBgImageIndex, setSelectedBgImageIndex] = useState(findImageBgIndex(backgroundImage));

  useEffect(() => {
    // Update icon when selectedImageIndex changes
    if (images[selectedImageIndex]) {
      setIcon(images[selectedImageIndex].path);
    }

    console.log(selectedBgImageIndex);

    if (bgImages[selectedBgImageIndex]) {
      setBackgroundImage(bgImages[selectedBgImageIndex].path);
    }
  }, [selectedImageIndex, images, selectedBgImageIndex, bgImages]);

  // Ensure icon state and selectedImageIndex are in sync when icon changes externally (e.g., dropdown change)
  useEffect(() => {
    setSelectedImageIndex(findImageIndex(icon));
    setSelectedBgImageIndex(findImageBgIndex(backgroundImage));
  }, [icon, images, backgroundImage, bgImages]);

  const handlePreviousImage = () => {
    setSelectedImageIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNextImage = () => {
    setSelectedImageIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const handlePreviousBgImage = () => {
    setSelectedBgImageIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : bgImages.length - 1));
  };

  const handleNextBgImage = () => {
    setSelectedBgImageIndex(prevIndex => (prevIndex + 1) % bgImages.length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Retrieve the CSRF token from the meta tag
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    const designData = {
      color,
      currency,
      borderColor,
      borderWidth,
      backgroundImage,
      icon,
      currencyName,
      numberText,
      wordText,
      isRound
    };

    // Send the data to your Rails backend
    try {
      const response = await fetch("/downloads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken, // Include the CSRF token in the request headers
        },
        body: JSON.stringify(designData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);

        location.href = `/downloads/${result.hex_id}`;
        // Handle success, e.g., redirecting or showing a success message
      } else {
        // Handle server errors or invalid responses
        console.error("Server responded with non-OK status:", response.status);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
<div className="flex flex-col sm:flex-row gap-4">
    {/* Preview for Mobile */}
    <div className="w-full sm:hidden p-4 bg-gray-200 shadow-md rounded-lg flex flex-col items-center justify-start">
        {/* Duplicate the Preview content here */}
        <div className="text-center text-xl font-bold mb-4">Preview</div>
        <div className="flex items-center justify-center">
                    <Money
            backgroundImage={backgroundImage}
            color={color}
            borderColor={borderColor}
            borderWidth={borderWidth}
            isRound={isRound}
            icon={icon}
            wordText={wordText}
            currencyName={currencyName}
            currency={currency}
            numberText={numberText}
          />
        </div>
    </div>


    <div className="w-full sm:w-1/2 p-4 bg-white shadow-md rounded-lg">
        <div className="p-4 rounded-lg">
          <button
            onClick={handleSubmit}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          >
            Create
          </button>

          {/* Options */}
          <div>

            {/* Text Section */}
            <div className="text-lg font-bold mt-4 mb-2">Text</div>

            <div className="isolate -space-y-px rounded-md shadow-sm">
              <div className="relative rounded-md rounded-b-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Currency</label>
                <select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-lg sm:text-sm sm:leading-6"
                >
                  <option value="">No symbol</option>
                  <option value="$">$</option>
                  <option value="€">€</option>
                  <option value="£">£</option>
                  <option value="¥">¥</option>
                </select>
              </div>
              <div className="relative px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Currency Name</label>
                <input
                  type="text"
                  value={currencyName}
                  onChange={(e) => setCurrencyName(e.target.value)}
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="relative px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Number Text</label>
                <input
                  type="text"
                  value={numberText}
                  onChange={(e) => setNumberText(e.target.value)}
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="relative rounded-md rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Word Text</label>
                <input
                  type="text"
                  value={wordText}
                  onChange={(e) => setWordText(e.target.value)}
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="text-lg font-bold mt-4 mb-2">Image</div>

            {/* Image Section */}
            <div className="isolate -space-y-px rounded-md shadow-sm">
              <div className="relative rounded-md rounded-b-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Background Image URL</label>
                <select
                  value={backgroundImage}
                  onChange={(e) => setBackgroundImage(e.target.value)}
                  className="block w-full border-0 p-0 py-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                >
                  {bgImages.map((image, index) => (
                    <option key={index} value={image.path}>{image.name}</option>
                  ))}
                </select>

                <div className="flex">
                  <button
                    type="button"
                    onClick={handlePreviousBgImage}
                    className="flex justify-center items-center bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold px-4 rounded-l w-1/2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                  </button>

                  <button
                    type="button"
                    onClick={handleNextBgImage}
                    className="flex justify-center items-center bg-gray-300 font-bold hover:bg-gray-400 px-4 rounded-r text-gray-800 w-1/2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                  </button>
                </div>

              </div>
              <div className="relative px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Icon</label>
                <select
                  value={icon}
                  onChange={(e) => setIcon(e.target.value)}
                  className="block w-full border-0 p-0 py-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                >
                  {images.map((image, index) => (
                    <option key={index} value={image.path}>{image.name}</option>
                  ))}
                </select>

                <div className="flex">
                  <button
                    type="button"
                    onClick={handlePreviousImage}
                    className="flex justify-center items-center bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold px-4 rounded-l w-1/2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                  </button>

                  <button
                    type="button"
                    onClick={handleNextImage}
                    className="flex justify-center items-center bg-gray-300 font-bold hover:bg-gray-400 px-4 rounded-r text-gray-800 w-1/2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="relative rounded-md rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Image Shape</label>
                <div className="flex py-2">
                  <button
                    type="button"
                    onClick={() => setIsRound("1")}
                    className={`flex justify-center items-center bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold px-4 rounded-l w-1/2 ${isRound === "1" ? 'bg-gray-500' : ''}`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 12a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                    </svg>
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsRound("0")}
                    className={`flex justify-center items-center bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold px-4 rounded-r w-1/2 ${isRound === "0" ? 'bg-gray-500' : ''}`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 6C6 5.44772 6.44772 5 7 5h10c0.55228 0 1 0.447716 1 1v10c0 0.55228 -0.44772 1 -1 1H7c-0.55228 0 -1 -0.44772 -1 -1V6Z" />
                    </svg>
                  </button>
                </div>

              </div>
            </div>

            <div className="text-lg font-bold mt-4 mb-2">Colors</div>

            {/* Color Section */}
            <div className="isolate -space-y-px rounded-md shadow-sm">
              <div className="relative rounded-md rounded-b-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Color</label>
                <input
                  type="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  className="block w-full bg-transparent border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="relative px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Border Color</label>
                <input
                  type="color"
                  value={borderColor}
                  onChange={(e) => setBorderColor(e.target.value)}
                  className="block w-full bg-transparent border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="relative rounded-md rounded-t-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                <label className="block text-xs font-medium text-gray-500">Border Width</label>
                <input
                  type="range"
                  min="1"
                  max="3"
                  value={borderWidth}
                  onChange={(e) => setBorderWidth(e.target.value)}
                  className="block w-full border-0 p-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

          </div>
        </div>
      </div >

    {/* Preview for Desktop */}
    <div className="hidden sm:block w-full sm:w-1/2 p-4 bg-gray-200 shadow-md rounded-lg flex flex-col items-center justify-start">
        {/* Duplicate the Preview content here */}
        <div className="text-center text-xl font-bold mb-4">Preview</div>
        <div className="flex items-center justify-center">
          <Money
            backgroundImage={backgroundImage}
            color={color}
            borderColor={borderColor}
            borderWidth={borderWidth}
            isRound={isRound}
            icon={icon}
            wordText={wordText}
            currencyName={currencyName}
            currency={currency}
            numberText={numberText}
          />
        </div>
      </div>

    </div >
  );
};

export default DesignBuilder;
